import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./Tabs.module.scss";
import Tab, { TabProps } from "./Tab";
import { Button, IconMS, OverrideTheme, Spinner } from "SiteComponents";
import Sort, { SortProps } from "./Sort";
import useWindowSize from "src/utils/useWindowSize";

export interface TabsProps {
  defaultActiveTab?: string;
  size?: "small" | "medium" | "large";
  type?: "primary" | "secondary";
  tabList: TabProps[];
  PanelComponent?: React.FC<any>; // FE only
  panelComponentProps?: any; // FE only
  onTabChange?: (id: string) => void;
  onSortChange?: (sortKey: string, sortOrder: string) => void;
  clearFilters?: () => void;
  isLoading?: boolean;
  sortOptions?: SortProps;
  clearFiltersText?: string;
}

export const Tabs = ({
  defaultActiveTab,
  tabList,
  sortOptions,
  PanelComponent,
  panelComponentProps,
  size,
  type,
  onTabChange,
  onSortChange,
  isLoading,
  clearFilters,
  clearFiltersText,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || "");
  const isDesktop = useWindowSize().width > 768;

  const onTabChangeRef = useRef(onTabChange);

  useEffect(() => {
    onTabChangeRef.current = onTabChange;
  }, [onTabChange]);

  useEffect(() => {
    if (onTabChangeRef.current && activeTab) {
      onTabChangeRef.current(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={styles.tabs}>
      <div role="tablist" aria-label="Tabs">
        {tabList && (
          <ul className={styles.list}>
            {tabList.map(tab => (
              <Tab
                key={tab.id}
                {...tab}
                size={size}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ))}
            {clearFilters && isDesktop && (
              <li className={styles.clearFilter}>
                <OverrideTheme>
                  <Button onClick={clearFilters} theme="primary">
                    {clearFiltersText}
                    <IconMS name="close" />
                  </Button>
                </OverrideTheme>
              </li>
            )}
            {sortOptions && sortOptions.sortOptionList && (
              <li className={styles.sortContainer}>
                <Sort {...sortOptions} onChange={onSortChange} />
              </li>
            )}
          </ul>
        )}
      </div>
      <div
        id={`panel-${activeTab}`}
        role="tabpanel"
        aria-labelledby={`tab-${activeTab}`}
        aria-live="polite"
        tabIndex={0} // Focusable for screen readers
        className={classNames(styles.panel, {
          [styles[size || "medium"]]: size,
          [styles[type || "primary"]]: type,
        })}
      >
        {PanelComponent && panelComponentProps && (
          <>
            {isLoading && <Spinner />}
            <PanelComponent {...panelComponentProps} isInsideTabs />
          </>
        )}
      </div>
    </div>
  );
};

export default Tabs;
