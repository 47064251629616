import ContentArea, { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import styles from "./ReportSubChapterBlock.module.scss";

export interface ReportSubChapterBlockProps {
  heading: string;
  text: ContentAreaProps;
  index: number;
}

const ReportSubChapterBlock = ({heading, text, index}: ReportSubChapterBlockProps) => {
  return <div>
    <a className={styles.anchor} id={index.toString()}></a>
    <h2 className={styles.heading}>{heading}</h2>
    <ContentArea {...text} noGrid className={styles.contentArea}/>
  </div>;
};

export default ReportSubChapterBlock;
