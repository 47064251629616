import React from "react";
import styles from "./ResultsPageHeroBlock.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import SearchField, {
  SearchFieldProps,
} from "components/base/SearchField/SearchField";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";
import classNames from "classnames";

export interface ResultsPageHeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  mainIntro: string;
  search: SearchFieldProps;
  resultsType?: string;
}

const ResultsPageHeroBlock: React.FC<ResultsPageHeroBlockProps> = ({
  heading,
  mainIntro,
  search,
  resultsType
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();

  return (
    <div
      className={classNames(backgroundClass, {
        [styles.dark]: theme === "dark",
      })}
    >
      <BlockContainer
      className={classNames(styles.resultsPageHeroBlock, backgroundClass, {
        [styles.dark]: theme === "dark",
      })}
      verticalPadding
    >
      <div className={classNames({[styles.hidden]: resultsType === "SearchPage"})}>
        <h1 className={styles.heading}>{heading}</h1>
        <p className={styles.ingress}>{mainIntro}</p>
      </div>
      {search && <SearchField {...search} wide/>}
    </BlockContainer>
    </div>
  );
};

export default ResultsPageHeroBlock;
