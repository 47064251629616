import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Truncate.module.scss";
import { setTabIndexes } from "src/utils/setTabIndexes";

interface TruncateProps {
  children: ReactNode;
  expandText: string;
  truncateText: string;
  truncatedHeight?: number;
  className?: string;
}

const Truncate = ({
  children,
  expandText,
  truncateText,
  truncatedHeight,
  className,
}: TruncateProps) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [needsTruncation, setNeedsTruncation] = useState(false);
  const [fullHeight, setFullHeight] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const lineHeight = parseFloat(
          getComputedStyle(contentRef.current).lineHeight
        );
        const truncateHeight = truncatedHeight ?? lineHeight * 2 + 4;

        setMaxHeight(truncateHeight);
        setNeedsTruncation(contentHeight > truncateHeight);
        setFullHeight(contentHeight);
      }
    });

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [children]);

  const toggleTruncate = () => {
    if (isTruncated) {
      setIsAnimating(true);
      setIsTruncated(false);
    } else {
      setIsTruncated(true);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }
  };

  useEffect(() => {
    const node = contentRef.current;
    if (!node) return;
    if (!isTruncated) {
      setTabIndexes(node, "0");

      const links = node.querySelectorAll("a");
      if (links.length > 0) {
        links[0].focus();
      }
    } else {
      setTabIndexes(node, "-1");
    }
  }, [isTruncated]);

  return (
    <div className={classNames(className, styles.truncateContainer)}>
      <div
        className={styles.truncate}
        ref={contentRef}
        style={{
          display: isTruncated && !isAnimating ? "-webkit-box" : "block",
          WebkitLineClamp:
            isTruncated && !isAnimating && needsTruncation ? 2 : "unset",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
          maxHeight:
            isTruncated && needsTruncation
              ? `${maxHeight}px`
              : `${fullHeight}px`,
        }}
      >
        {children}
      </div>
      {needsTruncation && (
        <>
          <button
            className={styles.button}
            onClick={toggleTruncate}
            aria-hidden={true}
          >
            {isTruncated ? expandText : truncateText}
          </button>
        </>
      )}
    </div>
  );
};

export default Truncate;
