import React from "react";
import styles from "./ReportPageHeroBlock.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import MultiMediaBlock from "../MultiMediaBlock/MultiMediaBlock";
import IconMS from "src/components/utils/IconMS/IconMS";
import { ImageProps } from "../../../components/base/Image/Image";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";

export interface ReportPageHeroBlockProps extends ReactComponentPropsBase {
  byline?: string;
  heading: string;
  image?: ImageProps;
  mediaText?: string;
  publishDate?: string;
  showLessText?: string;
  showMoreText?: string;
  subHeading?: string;
  useLargeMedia?: boolean;
}

const ReportPageHeroBlock: React.FC<ReportPageHeroBlockProps> = ({
  byline,
  heading,
  image,
  mediaText,
  publishDate,
  showLessText,
  showMoreText,
  subHeading,
  useLargeMedia,
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();
  return (
    <div className={backgroundClass}>
      <div
        className={classNames(styles.reportPageHeroBlock, {
          [styles.withMedia]: image,
          [styles.largeMedia]: useLargeMedia,
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.reportPageHeroBlockText}>
          {subHeading && <p>{subHeading}</p>}
          {heading && <h1>{heading}</h1>}
          {publishDate && (
            <span>
              <IconMS className={styles.icon} name="stylus" size="20px" />
              <p>{publishDate}</p>
            </span>
          )}
        </div>
        {image && (
          <div className={styles.reportPageHeroBlockMedia}>
            <MultiMediaBlock
              image={image}
              byline={byline}
              expandButtonText={showMoreText}
              closeButtonText={showLessText}
              isLargeMedia={true}
              onDarkBackground={theme === "dark"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportPageHeroBlock;
