import React from "react";
import styles from "./FilterBlock.module.scss";
import { FilterListContainer, FilterVertical } from "SiteComponents";
import { FilterVerticalProps } from "./FilterVertical/FilterVertical";
import { Filter } from "./FilterVertical/FilterList";
import { CheckboxProps } from "components/base/Checkbox/Checkbox";
import { SharedFilterListContainerProps } from "./FilterListContainer/FilterListContainer";

export interface FilterBlockProps extends SharedFilterListContainerProps {
  filterState?: FilterVerticalProps[];
  onFilterChange?: (filters: any) => void;
  handleShowResults?: () => void;
  clearAllFilters?: () => void;
}

const FilterBlock: React.FC<FilterBlockProps> = ({
  heading,
  labels,
  filterState,
  onFilterChange = () => {},
  handleShowResults = () => {},
  clearAllFilters = () => {},
}) => {
  const handleFilterGroupChange = (
    updatedFilterGroupId: string,
    updatedFilters: CheckboxProps[]
  ) => {
    let didUpdateFilterState = false;

    const newFilterState = filterState?.map(filterGroup => {
      if (filterGroup.id === updatedFilterGroupId) {
        didUpdateFilterState = true;
        return { ...filterGroup, filters: updatedFilters };
      }
      return filterGroup;
    });

    if (didUpdateFilterState) {
      onFilterChange(newFilterState);
    }

    return newFilterState;
  };

  var filters = filterState?.flatMap(filter => filter.filters) ?? [];
  var count = filters.filter(filter => filter.checked).length;

  return (
    <FilterListContainer
      heading={heading}
      handleClearAll={clearAllFilters}
      labels={labels}
      handleShowResults={handleShowResults}
      filterGroupCount={count}
    >
      <ul className={styles.filterList}>
        {filterState?.map(filterGroup => {
          return (
            <li key={filterGroup.id}>
              <FilterVertical
                {...filterGroup}
                filters={filterGroup.filters}
                handleFilterGroupChange={handleFilterGroupChange}
              />
            </li>
          );
        })}
      </ul>
    </FilterListContainer>
  );
};

export default FilterBlock;
