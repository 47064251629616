import React, { useState } from "react";
import styles from "./FilterListContainer.module.scss";
import { Button, Icon, IconMS, OverrideTheme } from "SiteComponents";
import useWindowSize from "src/utils/useWindowSize";
import classNames from "classnames";
import useDisableScroll from "src/utils/useDisableScroll";

export interface SharedFilterListContainerProps {
  heading?: string;
  labels?: {
    openFilters?: string;
    closeFilters?: string;
    clearAllOutside?: string;
    clearAllInside?: string;
  };
}
export interface FilterListContainerProps
  extends SharedFilterListContainerProps {
  children: React.ReactNode;
  clearLabel?: string;
  showLabel?: string;
  openFiltersLabel?: string;
  filterGroupCount?: number;
  handleClearAll: () => void;
  handleShowResults: () => void;
}

const FilterListContainer: React.FC<FilterListContainerProps> = ({
  children,
  heading,
  handleClearAll,
  labels = {},
  filterGroupCount,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  useDisableScroll(isFiltersOpen);

  const isDesktop = useWindowSize().width > 768;

  if (isDesktop) return children;

  const handleOpenFilters = () => {
    setIsFiltersOpen(true);
  };

  const handleCloseFilters = () => {
    setIsFiltersOpen(false);
  };

  const { openFilters, closeFilters, clearAllOutside, clearAllInside } = labels;

  return (
    <div
      className={classNames(styles.filterListContainer, {
        [styles.showMobileFilters]: isFiltersOpen,
      })}
    >
      {!isFiltersOpen && (
        <div className={styles.outerFilterActions}>
          {openFilters && (
            <OverrideTheme>
              <Button onClick={handleOpenFilters} theme="secondary">
                <Icon className={styles.filterListIcon} name="vector" />
                {`${openFilters} (${filterGroupCount})`}
              </Button>
            </OverrideTheme>
          )}

          {clearAllOutside && (
            <OverrideTheme>
              <Button onClick={handleClearAll} theme="primary">
                {clearAllOutside}
                <IconMS name="close" />
              </Button>
            </OverrideTheme>
          )}
        </div>
      )}

      {isFiltersOpen && (
        <>
          <div className={styles.header}>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            <OverrideTheme>
              <Button
                onClick={handleClearAll}
                className={styles.clearAllButton}
                buttonText={clearAllInside}
                theme="tertiary"
              />
            </OverrideTheme>
          </div>
          <div className={styles.filterList}>{children}</div>
          <div className={styles.filterAction}>
            <OverrideTheme>
              <Button
                onClick={handleCloseFilters}
                className={styles.showResultsButton}
                buttonText={closeFilters}
                theme="primary"
              />
            </OverrideTheme>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterListContainer;
