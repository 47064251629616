import React from "react";
import styles from "./AccordionItemBlock.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import AccordionBase from "../../utils/Accordion/AccordionBase";
import ProgramItemBlock, {
  ProgramItemBlockProps,
} from "../ProgramItemBlock/ProgramItemBlock";
import { LinkProps } from "src/base-props/LinkProps";
import { CtaLinkBlock } from "SiteComponents";
import classNames from "classnames";
import StatisticsBlock, {
  StatisticsBlockProps,
} from "../StatisticsBlock/StatisticsBlock";

export interface AccordionItemBlockProps {
  closeLabel?: string;
  heading: string;
  linkButton?: CTAProps;
  openLabel?: string;
  text?: ContentAreaProps;
  program?: ProgramItemBlockProps[];
  linkList?: LinkProps[];
  activeIndex?: number;
  small?: boolean;
  initiallyOpen?: boolean;
  statistics?: StatisticsBlockProps;
  onClick?: () => void;
}

const AccordionItemBlock: React.FC<AccordionItemBlockProps> = ({
  closeLabel,
  heading,
  linkButton,
  openLabel,
  text,
  program,
  linkList,
  activeIndex,
  small,
  initiallyOpen,
  statistics,
  onClick,
}) => {
  return (
    <AccordionBase
      {...{
        closeLabel,
        heading,
        openLabel,
        isGlobal: true,
        small,
        isInitiallyOpen: initiallyOpen,
      }}
    >
      <div
        className={classNames(styles.accordionItemBlock, {
          [styles.small]: small,
        })}
      >
        {program &&
          program.map((event, index) => (
            <ProgramItemBlock key={index} {...event} />
          ))}
        {linkList && linkList.length > 0 && (
          <ol className={styles.linkList}>
            {linkList.map((link, index) => (
              <li
                className={classNames(styles.listItem, {
                  [styles.active]: activeIndex === index,
                })}
              >
                <a
                  href={link.url}
                  className={styles.link}
                  onClick={onClick}
                  key={index}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ol>
        )}
        {statistics && <StatisticsBlock {...statistics} />}
        <div className={styles.richText}>
          {text && <ContentArea isInsideBlock {...text} />}
        </div>
        {linkButton && (
          <CTA
            className={styles.linkButton}
            size="small"
            theme="primary"
            {...linkButton}
          />
        )}
      </div>
    </AccordionBase>
  );
};

export default AccordionItemBlock;
