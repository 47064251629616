import classNames from "classnames";
import styles from "./GlobalCardBlock.module.scss";
import Media from "components/utils/Media/Media";
import IconMS from "../../utils/IconMS/IconMS";
import Tag, { TagProps } from "components/base/Tag/Tag";
import { ImageProps } from "components/base/Image/Image";
import { LinkProps } from "src/base-props/LinkProps";

export interface GlobalCardBlockProps {
  icon?: string;
  iconRight?: boolean;
  image?: ImageProps;
  ingress?: string;
  isMedium?: boolean;
  subHeading?: string;
  theme?: "bold" | "moderate" | "outlined" | "soft" | "vivid";
  tags?: TagProps[]; 
  tagListTitle?: string;
  title: string;
  link?: LinkProps;
  type?:
    | "ReportPage"
    | "InfoPage"
    | "EventPage"
    | "TilskuddPage"
    | "GenericPage"
    | "ReportReadPage";
    className?: string;
}

const GlobalCardBlock = ({
  icon,
  iconRight,
  image,
  ingress,
  isMedium,
  subHeading,
  theme = "moderate",
  tags,
  tagListTitle,
  title,
  link,
  type,
  className,
}: GlobalCardBlockProps) => {
  return (
    <a
      href={link?.url}
      target={link?.target}
      className={classNames(styles.cardBlock, className, {
        [styles[theme]]: theme,
        [styles.isMedium]: isMedium,
        [styles.navigation]: type === "ReportReadPage",
        [styles.iconRight]: iconRight,
      })}
    >
      <div
        className={classNames(styles.cardBlockMedia, {
          [styles.withIcon]: icon,
        })}
      >
        {icon && <IconMS className={styles.icon} name={icon} size="48px" />}
        {image && (
          <Media
            className={classNames({ [styles.medium]: isMedium })}
            image={{
              ...image,
              ratio: type === "ReportPage" ? "medium" : "standard",
            }}
          />
        )}
      </div>
      <div className={styles.cardBlockText}>
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        <h2>
          {title}
          {link?.target && (
            <IconMS
              size="18px"
              className={styles.linkIcon}
              name={"open_in_new"}
            />
          )}
        </h2>
        <div className={styles.bottomSection}>
          {ingress && <p className={styles.ingress}>{ingress}</p>}
          {tags && (
            <ul className={styles.tagList} title={tagListTitle}>
              {tags
                .map((tag, index) => (
                  <li key={index}>
                    {index < 2 ? (
                      <Tag {...tag} className={styles.tag} />
                    ) : (
                      <Tag
                        text={`+${index - 1}`}
                        className={styles.numberTag}
                      />
                    )}
                  </li>
                ))
                .slice(0, 3)}
            </ul>
          )}
        </div>
      </div>
    </a>
  );
};

export default GlobalCardBlock;
