import styles from "./ProgramBlock.module.scss";
import ProgramItemBlock, {
  ProgramItemBlockProps,
} from "../ProgramItemBlock/ProgramItemBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export interface ProgramBlockProps {
  heading?: string;
  richText?: ContentAreaProps;
  program?: ProgramItemBlockProps[];
}

const ProgramBlock = ({ heading, program, richText }: ProgramBlockProps) => {
  return (
    <div className={styles.programBlock}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      {richText && (
        <div className={styles.richText}>
          <ContentArea isInsideBlock {...richText} />
        </div>
      )}
      {program &&
        program.length > 0 &&
        program.map(block => <ProgramItemBlock {...block} />)}
    </div>
  );
};

export default ProgramBlock;
